body {
  width: 100%;
  color: #fff;
  background: black;
  color: $colors-gray-1000;
}

.content{
  padding-top: 50px;
  padding-bottom: 50px;
}

.svg-logo{
  max-width: 80%;
  max-height: 300px;
  vertical-align: bottom;
  padding: 15px 0;
  margin: 0 auto;
  margin-bottom: 50px;
}

.name{
  font-size: 22px;
  font-weight: 300;
}

.display-text{
  max-width: 700px;
  margin: 0 auto;
  padding-bottom: 20px;
}

.role{
  font-size: 22px;
  font-weight: 900;
}
.page-title{
  font-size: 28px;
  font-weight: 900;
  letter-spacing: 2px;
}

.title-subtle{
  opacity: .4;
}

.v-sm { display: block; }
.v-m, .v-lg { display: none; }

/* Larger than mobile */
@media (min-width: 400px) {
}

/* Larger than phablet (also point when grid becomes active) */
@media (min-width: 550px) {}

/* Larger than tablet */
@media (min-width: 750px) {
  .v-m { display: block; }
  .v-sm, .v-lg { display: none; }
}

/* Larger than desktop */
@media (min-width: 1000px) {
  .v-lg { display: block; }
  .v-sm, .v-m { display: none; }
}

/* Larger than Desktop HD */
@media (min-width: 1200px) {}

/* Navbar */
.navbar + .docs-section {
  border-top-width: 0; }
.navbar,
.navbar-spacer {
  display: block;
  width: 100%;
  height: 6.5rem;
  background: #fff;
  z-index: 99;
  border-top: 1px solid #eee;
  border-bottom: 1px solid #eee; }
.navbar-spacer {
  display: none; }
.navbar > .container {
  width: 100%; }
.navbar-list {
  list-style: none;
  margin-bottom: 0; }
.navbar-item {
  position: relative;
  float: left;
  margin-bottom: 0; }
.navbar-link {
  text-transform: uppercase;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: .2rem;
  margin-right: 35px;
  text-decoration: none;
  line-height: 6.5rem;
  color: #222; }
.navbar-link.active {
  color: #A5D8FF; }
.has-docked-nav .navbar {
  position: fixed;
  top: 0;
  left: 0; }
.has-docked-nav .navbar-spacer {
  display: block; }
/* Re-overiding the width 100% declaration to match size of % based container */
.has-docked-nav .navbar > .container {
  width: 80%; }

.d-flex{
  display: flex;
}
.align-center{
  align-items: center;
}
.justify-center{
  justify-content: center;
}

.nav-icon{
  margin-right: 30px!important;
  min-width: 100px;
}

.header{
  box-shadow: rgba(31,53,78,.21) 0 1px;
  padding: 15px 0;
  position: fixed;
  width: 100%;
  background-color: transparent;
  z-index: 2;
  -webkit-transition: background-color 0.2s cubic-bezier(0.2, 0, 0.2, 1), width .3s ease;
  transition: background-color 0.2s cubic-bezier(0.2, 0, 0.2, 1), width .3s ease;
}

.header.light-content:not(.light-bk):not(.show-only-nav){
  .button-light{
    border-color: $brand-white;
    color: $brand-white;
  }
  .button-light:hover{
    color: $brand-blue;
    border: 2px solid $brand-white;
    background: $brand-white;
  }
  .hamburger{
    border-color: $brand-white;
  }
  .header-logo-wrap{
    color: $brand-white;
  }
}

.header-logo-wrap{
  display: flex;
  align-items: center;
  text-transform: uppercase;
  @extend .bold;

  .header-logo{
    margin-right: 15px;
  }

  .hocnest-icon-wrap {
    display: inline-block;
    width: 50px;
  }

  .header-logo-img {
    max-height: 40px;
    height: auto;
  }
}

svg.header-logo{
  width: 45px;
}

svg.hawk-icon {
  width: 100%;
  height: auto;
}

.header.light-bk{
  background-color: rgba(255,255,255,0.95);
  -webkit-box-shadow: 0 3px 5px -3px rgba(0,0,0,0.5);
  box-shadow: 0 3px 5px -3px rgba(0,0,0,0.5);
}

.header .navbar-link{
  color: white;
}

.header.light-bk .navbar-link{
  color: inherit;
}

.header.light-content {
  .hoc-icon .hoc-icon__cls-2 {
    fill: $colors-gray-100;
  }
  .hoc-icon .hoc-icon__cls-1 {
    fill: $colors-accent;
  }
}

.header {
  .hoc-icon .hoc-icon__cls-2 {
    fill: $colors-accent;
  }
  .cls-1 {
    fill: $colors-primary;
  }
}

.header.light-bk .hoc-icon .hoc-icon__cls-2 {
  fill: $colors-gray-1000;
}

.header.light-bk .hoc-icon .hoc-icon__cls-1 {
  fill: $colors-accent;
}

.hero-image {
}

.hero-title {
  @include relative-font-size(4.0);
  line-height: 1.1;
  margin-bottom: 10px;
}
.hero-subheader{
  @include relative-font-size(1.25);
}
.hero-subtitle{
  @include relative-font-size(2.25);
}

.hero{
  background: $brand-color;
  overflow: hidden;
  min-height: 600px;
  color: #ffffff;
  padding: 50px 0;
  font-size: 68px;
  font-size: 18px;
  position: relative;
}

.hero-2{
  background-position: center;
  min-height: 400px;
  color: #EFF1F3;
  padding: 50px 0;
  font-size: 68px;
  position: relative;
}

.hero-3{
  background-position: center;
  background-size: cover;
  min-height: 400px;
  color: #EFF1F3;
  padding: 50px 0;
  font-size: 68px;
  position: relative;
}

.hero-4{
  background: black;
  min-height: 400px;
  color: #EFF1F3;
  padding: 50px 0;
  font-size: 68px;
  position: relative;
}

.hero-5{
  background-image: url(/img/resources/usa.png);
  background-position: center;
  background-size: cover;
  min-height: 250px;
  color: #EFF1F3;
  padding: 50px 0;
  font-size: 68px;
  position: relative;
}

.hero-6{
  background: url("/img/resources/zig.png"), linear-gradient(180deg, white, $brand-white), center no-repeat $brand-navy scroll;
  min-height: 450px;
  color: #0C2340;
  padding: 50px 0;
  font-size: 68px;
  position: relative;
}

.hero-7{
  min-height: 450px;
  color: #0C2340;
  padding: 50px 0;
  font-size: 68px;
  position: relative;
}

.hero-8{
  background-image: url(/img/resources/background-squares.png);
  min-height: 450px;
  color: #0C2340;
  padding: 50px 0;
  font-size: 68px;
  position: relative;
}

.hero-9 {
  padding: 100px 0;
  background: white;

  .hero__content {
    flex-direction: row;
  }

  .hero__figure {
    width: 100%;
    text-align: center;

    img, svg {
      width: 70%;
      height: auto;
    }
  }
}

.hero-10 {
  padding: 100px 0;
  background: white;

  .hero__content {
    flex-direction: row;
  }

  .hero__figure {
    width: 100%;
    text-align: center;

    img, svg {
      width: 70%;
      max-height: 500px;
      height: auto;
    }
  }
}

.hero-11 {
  padding: 100px 0;
  background: white;
  color: $colors-gray-900;
  min-height: 700px;

  .hero-11__content {
    max-width: 1024px;
    padding-top: 100px;
  }

  .hero__figure {
    width: 100%;
    text-align: center;

    img, svg {
      width: 70%;
      max-height: 500px;
      height: auto;
    }
  }

  .hero-11__body {
    display: grid;
    grid-template-columns: repeat(2,minmax(0,1fr));
    gap: 1rem;
  }

  .hero-11__graphic {
    max-width: 1000px;
    margin: 0 auto;
  }
}

@media (max-width: 550px) {
  .hero-11 {
    .hero-11__content {
      .button-large {
        width: 100%;
        margin-bottom: 10px;
      }
    }
  }
}

@media (max-width: 1300px) {
  .hero-11 {
    .hero-11__body {
      display: grid;
      grid-template-columns: repeat(1,minmax(0,1fr));
      gap: 1rem;
    }
  }
}

.hero__code-lines {
  position: absolute;
  z-index: 0;
  opacity: .04;
  top: -100px;
  left: 0;
  width: 100%;
}

@media (max-width: 850px) {
  .hero__code-lines-1 {
    display: none;
  }

  .hero__code-lines-2 {
    left: 0;
    right: 0;
    bottom: -20px;
  }
}

.hero__content {
  text-align: center;
}

@media (min-width: 750px) {
  .hero__content {
    display: flex;
    align-items: center;
    text-align: right;
  }

  .hero-10 .hero__content {
    display: flex;
    align-items: center;
    text-align: left;
  }
}

@mixin sectionize($background, $color) {
  color: $color;
  background: $background;
  padding: 50px 0;
  position: relative;
}

.section{
  background: $brand-white;
  padding: 50px 0;
  position: relative;
}
.section-title{
  font-size: 42px;
}

.section-body {
  font-size: 24px;
}

.section-gray {
  color: white;
  padding: 50px 0;
  position: relative;
  background: $hn-cyan;

  .hoc-icon .hoc-icon__cls-1 {
    fill: white;
  }

  .hoc-icon .hoc-icon__cls-2 {
    fill: $colors-gray-800;
  }
}

.section-primary {
  color: white;
  padding: 50px 0;
  position: relative;
  background: $colors-primary;
}

.section-dark {
  @include sectionize($background: $colors-gray-900, $color: white);
}

.section-light-grey {
  @include sectionize($background: $colors-gray-100, $color: $colors-gray-1000);
}

.section-white {
  @include sectionize($background: #fff, $color: #0C2340);
}

@media (max-width: 750px) {
  .learn-more{
    text-align: center;
    .section-title {
      font-size: 30px;
      padding-bottom: 10px;
    }
  }
  .hero-title{
    @include relative-font-size(2.5);
  }
  .hero-subheader{
    @include relative-font-size(1.5);
  }
}

@media (max-width: 1000px) {
  .hero-title{
    @include relative-font-size(2.65);
  }
  .hero-subheader{
    @include relative-font-size(1.65);
  }
}

.bold{
  font-weight: bold;
}

.text-gray-100 { color: $colors-gray-100 }
.text-gray-200 { color: $colors-gray-200 }
.text-gray-300 { color: $colors-gray-300 }
.text-gray-400 { color: $colors-gray-400 }
.text-gray-500 { color: $colors-gray-500 }
.text-gray-600 { color: $colors-gray-600 }
.text-gray-700 { color: $colors-gray-700 }
.text-gray-800 { color: $colors-gray-800 }
.text-gray-900 { color: $colors-gray-900 }
.text-gray-1000 { color: $colors-gray-1000 }
.text-yellow-100 { color: $colors-yellow-100 }
.text-yellow-200 { color: $colors-yellow-200 }
.text-yellow-300 { color: $colors-yellow-300 }
.text-yellow-400 { color: $colors-yellow-400 }
.text-yellow-500 { color: $colors-yellow-500 }
.text-yellow-600 { color: $colors-yellow-600 }
.text-yellow-700 { color: $colors-yellow-700 }
.text-yellow-800 { color: $colors-yellow-800 }
.text-yellow-900 { color: $colors-yellow-900 }
.text-yellow-1000 { color: $colors-yellow-1000 }
.text-red-100 { color: $colors-red-100 }
.text-red-200 { color: $colors-red-200 }
.text-red-300 { color: $colors-red-300 }
.text-red-400 { color: $colors-red-400 }
.text-red-500 { color: $colors-red-500 }
.text-red-600 { color: $colors-red-600 }
.text-red-700 { color: $colors-red-700 }
.text-red-800 { color: $colors-red-800 }
.text-red-900 { color: $colors-red-900 }
.text-red-1000 { color: $colors-red-1000 }
.text-teal-100 { color: $colors-teal-100 }
.text-teal-200 { color: $colors-teal-200 }
.text-teal-300 { color: $colors-teal-300 }
.text-teal-400 { color: $colors-teal-400 }
.text-teal-500 { color: $colors-teal-500 }
.text-teal-600 { color: $colors-teal-600 }
.text-teal-700 { color: $colors-teal-700 }
.text-teal-800 { color: $colors-teal-800 }
.text-teal-900 { color: $colors-teal-900 }
.text-teal-1000 { color: $colors-teal-1000 }
.text-indigo-100 { color: $colors-indigo-100 }
.text-indigo-200 { color: $colors-indigo-200 }
.text-indigo-300 { color: $colors-indigo-300 }
.text-indigo-400 { color: $colors-indigo-400 }
.text-indigo-500 { color: $colors-indigo-500 }
.text-indigo-600 { color: $colors-indigo-600 }
.text-indigo-700 { color: $colors-indigo-700 }
.text-indigo-800 { color: $colors-indigo-800 }
.text-indigo-900 { color: $colors-indigo-900 }
.text-indigo-1000 { color: $colors-indigo-1000 }
.text-primary {color: $colors-primary }
.text-accent {color: $colors-accent }

.p-t-10 { padding-top: 10px!important }
.p-b-10 { padding-bottom: 10px !important }

.p-t-50 { padding-top: 50px!important }
.p-b-50 { padding-bottom: 50px!important }

.p-t-25 { padding-top: 25px!important }
.p-b-25 { padding-bottom: 25px !important }

.u-right { text-align: right }

.action-wrap{
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;
}

.navbar-list{
  display: none;
}

/*Hamburger*/
.hamburger{
  border-color: $colors-indigo-300;
  width: 25px;
  padding-bottom: 6px;
  cursor: pointer;
  display: block;
}
.hamburger-patty{
  float: right;
  margin-top: 6px;
  width: 100%;
  border-top: 3px solid;
  border-color: inherit;
  -webkit-transform-origin: right center;
  transform-origin: right center;
  -webkit-transition: all 0.33s cubic-bezier(0.33, 0, 0.2, 1);
  transition: all 0.33s cubic-bezier(0.33, 0, 0.2, 1);
}

.active .ham-one{
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}
.active .ham-two{
  opacity: 0;
}
.active .ham-three{
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}




/* OVERRIDES */
@media (min-width: 750px) {
  .one-half.actual{
    margin-left: 0;
    width: 50%;
  }
  .hamburger{
    /*display: none;*/
  }
  .navbar-list{
    /*display: block;*/
  }
}

.m-r-20{
  margin-right: 20px;
}

.p-t-50{
  padding-top: 50px;
}

.p-t-100{
  padding-top: 100px;
}

.p-t-200{
  padding-top: 200px;
}

.p-t-300{
  padding-top: 300px;
}

.page-wrap{
  background: #fcfcfc;
  min-height: 100vh;
  position: relative;
}

.header.show-only-nav{
  position: relative;
}

@media (max-width: 750px) {
  .page-wrap.show-only-nav .main{
    display: none;
  }

  .page-wrap.show-only-nav footer{
    display: none;
  }

  .page-wrap.show-only-nav .nav-overlay{
    display: block;
    position: relative;
    right: 0;
    width: 100%;
    height: 100%;
    -webkit-transition: none;
    transition: none;
    padding-top: 0px;
  }
  .header{
    -webkit-transition: none;
    transition: none;

    &.show-only-nav{
      background-color: white;
    }
  }
}

@media (min-width: 750px) {
  .page-wrap.show-only-nav .nav-overlay{
    display: block;
    position: fixed;
    right: 0;
    width: 300px;
    height: 100%;
    text-align: right;
    padding-top: 0px;
  }

  .header.show-only-nav{
    width: calc(100% - 300px);
    position: fixed;
  }
}
.page-wrap.show-only-nav .nav-overlay{
  opacity: 1;
  right: 0;
}

.nav-overlay{
  opacity: 0;
  right: -300px;
  padding-top: 75px;
  height: 100%;
  position: fixed;
  z-index: 1;
  background: #fcfcfc;
  -webkit-transition: right .3s ease;
  transition: right .3s ease;
  -webkit-box-shadow: 0 0 100px 0 rgba(0,0,0,0.12);
  box-shadow: 0 0 100px 0 rgba(0,0,0,0.12);
}

.navbar-list2 {
  display: flex;
  flex-flow: column;
  list-style: none;
  margin-bottom: 0;
  margin-left: 0px;

  li.navbar-item{
    border-bottom: 1px solid #efefef;
    &.active{
      background-color: $colors-indigo-300;
      a{ color: $brand-white; }
    }
    a{
      padding-left: 30px;
      display: block;
    }
  }
}

/*Buttons*/
.button {
  margin-bottom: 0;
  font-size: 12px;
  height: unset;
  @extend .bold;
}
.button-blue {
  @extend .button;
  color: #fff;
  background-color: $hn-blue;
  border: 2px solid $hn-blue;
}
.button-blue:hover {
  color: #fff;
  border: 2px solid darken($hn-blue, 15%);;
  background: darken($hn-blue, 15%);;
}
.button-light {
  @extend .button;
  color: $colors-gray-1000;
  border: 2px solid $colors-gray-1000;
}
.button-light:hover {
  color: #fff;
  border: 2px solid $colors-gray-1000;
  background: $colors-gray-1000;
}
.button-white {
  @extend .button;
  color: $brand-navy;
  background: #ffffff;
  border: 2px solid #ffffff;
}
.button-white:hover {
  color: #fff;
  border: 2px solid $brand-blue;
  background: $brand-blue;
}
.button-red {
  @extend .button;
  color: #fff;
  background: $colors-red-500;
  border: 2px solid $colors-red-500;
}
.button-red:hover {
  color: #fff;
  border: 2px solid $colors-red-600;
  background: $colors-red-600;
}
.button-dark {
  @extend .button;
  color: #fff;
  border: 2px solid $colors-gray-1000;
  background: $colors-gray-1000;
}
.button-dark:hover {
  color: white;
  border: 2px solid $colors-gray-1000;
}
.button-large {
  font-size: 18px;
  padding: 10px 30px;
}

.m-r-30 {
  margin-right: 30px!important;
}

.m-l-auto {
  margin-left: auto!important;
}


/* details */
.details-wrap{
  display: flex;
  flex: 1;
  flex-flow: column;
}
.detail-wrap{
  display: flex;
  padding-top: 10px;

  .icon-wrap{
    color: $colors-indigo-400;
  }
}

/*footer*/
footer{
  padding: 50px 0;
  color: #999;
  background: white;

  .footer-links{
    @extend .d-flex;
    @extend .justify-center;

    list-style: none;
    flex-wrap: wrap;
  }
}

@media (max-width: 750px) {
  footer{
    .footer-links{
      li{
        flex: 1 50%;
      }
    }
  }
}

hr.light{
  border-top: 1px solid #2e3134;
}

.breadcrumbs{
  list-style: none;
  display: none;
  margin: 0;
  .breadcrumb-item{
    display: inline-block;
  }
}

.breadcrumbs .breadcrumb-item+.breadcrumb-item::before {
  display: inline-block;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  color: #818a91;
  content: "/";
}
@media (min-width: 750px) {
  .breadcrumbs{
    display: block;
  }
}

.m-text{
  @include relative-font-size(1.125);
}

// Elements
.cards{
  // display: flex;
  // align-items: center;
  // flex-wrap: wrap;

  .card{
    // flex-basis: auto;
    // flex: 1 100px;
    // text-align: center;
    // display: flex;
    // justify-content: center;
    // margin-bottom: 20px;
    // padding-right: 50px;

    img{
      -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
      filter: grayscale(100%);
      opacity: .7;
      max-height: 75px;

      &:hover{
        -webkit-filter: inherit;
        filter: inherit;
        opacity: 1;
      }
    }
  }
}

.t-border{
  border-top: 10px solid $brand-blue;

  &.navy{
    border-top: 10px solid $brand-navy;
  }
}

.b-border{
  border-bottom: 10px solid $brand-blue;

  &.navy{
    border-bottom: 10px solid $brand-navy;
  }
}

.y-borders{
  @extend .t-border;
  @extend .b-border;

  &.navy{
    @extend .t-border.navy;
    @extend .b-border.navy;
  }
}
