@charset "utf-8";

// Define defaults for each variable.

$base-font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol" !default;
$base-font-size:   16px !default;
$base-font-weight: 400 !default;
$small-font-size:  $base-font-size * 0.875 !default;
$base-line-height: 1.5 !default;

$spacing-unit:     30px !default;

$hn-red:            #6c464f !default;
$hn-blue:           #c5d9e2 !default;
$hn-cyan:           #62929e !default;
$hn-peach:          #d8aa96 !default;
$hn-green:          #404e4d !default;

$colors-gray-100: #F7F7F7 !default;
$colors-gray-200: #E1E1E1 !default;
$colors-gray-300: #CFCFCF !default;
$colors-gray-400: #B1B1B1 !default;
$colors-gray-500: #9E9E9E !default;
$colors-gray-600: #7E7E7E !default;
$colors-gray-700: #626262 !default;
$colors-gray-800: #515151 !default;
$colors-gray-900: #3B3B3B !default;
$colors-gray-1000: #222222 !default;

$colors-yellow-100: #FFFAEB !default;
$colors-yellow-200: #FCEFC7 !default;
$colors-yellow-300: #F8E3A3 !default;
$colors-yellow-400: #F9DA8B !default;
$colors-yellow-500: #F7D070 !default;
$colors-yellow-600: #E9B949 !default;
$colors-yellow-700: #C99A2E !default;
$colors-yellow-800: #A27C1A !default;
$colors-yellow-900: #7C5E10 !default;
$colors-yellow-1000: #513C06 !default;

$colors-red-100: #FFEEEE !default;
$colors-red-200: #FACDCD !default;
$colors-red-300: #F29B9B !default;
$colors-red-400: #E66A6A !default;
$colors-red-500: #D64545 !default;
$colors-red-600: #BA2525 !default;
$colors-red-700: #A61B1B !default;
$colors-red-800: #911111 !default;
$colors-red-900: #780A0A !default;
$colors-red-1000: #610404 !default;

$colors-teal-100: #EFFCF6 !default;
$colors-teal-200: #C6F7E2 !default;
$colors-teal-300: #8EEDC7 !default;
$colors-teal-400: #65D6AD !default;
$colors-teal-500: #3EBD93 !default;
$colors-teal-600: #27AB83 !default;
$colors-teal-700: #199473 !default;
$colors-teal-800: #147D64 !default;
$colors-teal-900: #0C6B58 !default;
$colors-teal-1000: #014D40 !default;

$colors-indigo-100: #E0E8F9 !default;
$colors-indigo-200: #BED0F7 !default;
$colors-indigo-300: #98AEEB !default;
$colors-indigo-400: #7B93DB !default;
$colors-indigo-500: #647ACB !default;
$colors-indigo-600: #4C63B6 !default;
$colors-indigo-700: #4055A8 !default;
$colors-indigo-800: #35469C !default;
$colors-indigo-900: #2D3A8C !default;
$colors-indigo-1000: #19216C !default;

$colors-primary: #6c464f !default;
$colors-accent: #d8aa96 !default;

$brand-color:       lime-green !default;
$brand-navy:        #3d5a80 !default;
$brand-blue:        #98c1d9 !default;
$brand-bright-blue: #e0fbfc !default;
$brand-orange:      #ee6c4d !default;
$brand-grey:        #f6f6f8 !default;
$brand-dark-grey:   #363636 !default;
$brand-light-grey:  #EFF1F3 !default;
$brand-white:       #FFFFFF !default;

$grey-color:       #828282 !default;
$grey-color-light: lighten($grey-color, 40%) !default;
$grey-color-dark:  darken($grey-color, 25%) !default;

$table-text-align: left !default;

// Width of the content area
$content-width:    800px !default;

$on-palm:          600px !default;
$on-laptop:        800px !default;

// Use media queries like this:
// @include media-query($on-palm) {
//   .wrapper {
//     padding-right: $spacing-unit / 2;
//     padding-left: $spacing-unit / 2;
//   }
// }
@mixin media-query($device) {
  @media screen and (max-width: $device) {
    @content;
  }
}

@mixin relative-font-size($ratio) {
  font-size: $base-font-size * $ratio;
}

// Import partials.
@import
  "amrani/normalize",
  "amrani/skeleton",
  "minima/base",
  "minima/layout",
  "amrani/base",
  "minima/syntax-highlighting"
;

svg#amrani_logo {
  .amrani_logo__cls-1 {
    fill: $brand-navy;
  }

  .amrani_logo__cls-2 {
    fill: $brand-blue;
  }
}


.partners-form {
  label {
    font-size: 0.8em;
  }

  .form-section {
    padding-bottom: 10px;
  }

  h2 {
    color: $colors-gray-900;
  }

  .radio-buttons {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;

    .radio-button {
      width: 50%;

      input[type="radio"] {
        margin-right: 10px;
      }

      label {
        cursor: pointer;

        &:after {
          clear: both;
          content: "";
          display: block;
        }
      }
    }

    .radio-button__location {
      @extend .radio-button;
    }

    .radio-button__budget {
      @extend .radio-button;
    }
  }
}

legend, label {
  &.required::after {
    content: "*";
  }
}

.primary-h4 {
  font-weight: 700;
  font-size: 18px;
  line-height: 1.3;
  margin-bottom: 10px;
  padding-top: 15px;
}

.secondary-p {
  font-size: 11pt;
  line-height: 1.5;
  margin: 0 0 10px;
  color: $brand-dark-grey;
}

.service-card__title {
  font-weight: 600;
  font-size: 30px;
  text-align: center;
  margin-bottom: 20px;
}

.service-card__description {
  font-size: 20px;
  line-height: 1.5;
  margin-bottom: 80px;
  font-weight: 300;
}

.service-tiles {
  display: grid;
  grid-template-columns: [left-gutter] 1fr [right-gutter];
  gap: 20px;
  row-gap: 20px;
  column-gap: 20px;

  .service-tile {
    padding: 52px 52px 40px;
    border: 1px solid $colors-gray-200;
    border-radius: 12px;

    .service-tile__img {
      svg {
        max-height: 300px;
        width: auto;
      }
    }
  }
}

@media (min-width: 950px) {
  .service-tiles {
    display: grid;
    grid-template-columns: [left-gutter] 1fr [center] 1fr [right-gutter];
  }
}

.services-img {
  height: 200px;
  margin: 0 auto;
  width: auto;
  display: flex;
  padding-bottom: 30px;
}

.services-icon {
  height: 70px;
  margin: 0;
  width: auto;
  display: flex;
  padding-bottom: 10px;
}

.a-heading {
  color: $brand-blue;
  font-size: 50px;
  font-weight: 300;
  margin-bottom: 20px;
  text-align: center;
}


.get-in-touch {
  text-transform: uppercase;
  font-size: 20px;
  letter-spacing: 1px;
  padding-right: 20px;
}

.card {
  padding-bottom: 25px;
  cursor: pointer;

  .card__icon {
    img {
      height: 70px;
    }
  }

  .card__link {
    h5 {
      padding-top: 20px;
      margin-bottom: 5px;
      letter-spacing: 2px;
      font-weight: 600;
      font-size: 15px;
    }

    p {
      font-weight: 400;
      font-size: 16px;
    }

    padding-bottom: 25px;
    border-bottom: 3px solid white;
    color: $colors-gray-1000;
  }

  &:hover {
    .card__link {
      border-bottom: 3px solid $colors-indigo-400;
      color: $colors-indigo-400;
    }
  }
}

.img-expand {
  img {
    height: auto;
    width: 70%;
  }
}

.svg-expand {
  svg {
    height: auto;
    width: 70%;
  }
}

@mixin heading-as-label($color: $grey-color) {
  font-weight: 400;
  letter-spacing: 0.125em;
  margin-bottom: 0.5rem;
  text-transform: uppercase;
  color: $color;
  font-size: 16px;
}

.heading-label {
  @include heading-as-label();
}

.heading-label-light {
  @include heading-as-label($grey-color-light);
}

.cta-link {
  color: $brand-blue;
  display: inline-block;
  font-weight: 500;
  padding-bottom: 0.125rem;
  -webkit-transition: border-color 200ms ease;
  transition: border-color 200ms ease;

  &:after {
    background: url(//thoughtbot.com/assets/icons/long-arrow-right-63404c54e8a761f04feee60300d59350ac471e23bba2a341d2ff91c11d1a8141.svg) top left no-repeat;
    content: "";
    display: block;
    height: 12px;
    margin-top: 0.375rem;
    transition: transform 150ms ease-in-out, -webkit-transform 150ms ease-in-out;
    width: 45px;
  }

  &:hover {
    &:after{
      transform: translateX(5px);
    }
  }
}

.icon-primary {
  font-size: 36px;
  color: $hn-cyan;
}

.icon-primary-sm {
  font-size: 24px;
  color: $hn-cyan;
}

.floating-nest {
  animation: floatAnim 4s ease-in-out infinite;
}

@keyframes floatAnim {
  0% {
    transform: translate(0,0);
  }
  50% {
    transform: translate(0,20px);
  }
  100% {
    transform: translate(0,0px);
  }
}

.ewform__submit-block {
  button {
    margin-bottom: 0;
    align-items: center;
  }
}

.ewform__textfield-block {
  input {
    margin-bottom: 0;
  }
}
