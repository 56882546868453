/**
 * Site header
 */
.site-header {
  border-top: 5px solid $grey-color-dark;
  border-bottom: 1px solid $grey-color-light;
  min-height: $spacing-unit * 1.865;

  // Positioning context for the mobile navigation icon
  position: relative;
}

.site-title {
  @include relative-font-size(1.625);
  font-weight: 300;
  line-height: $base-line-height * $base-font-size * 2.25;
  letter-spacing: -1px;
  margin-bottom: 0;
  float: left;
}


/**
 * Site footer
 */
.site-footer {
  border-top: 1px solid $grey-color-light;
  padding: $spacing-unit 0;
}

.footer-heading {
  @include relative-font-size(1.125);
  margin-bottom: $spacing-unit / 2;
}

.contact-list,
.social-media-list {
  list-style: none;
  margin-left: 0;
}

.footer-col-wrapper {
  @include relative-font-size(0.9375);
  color: $grey-color;
  margin-left: -$spacing-unit / 2;
  @extend %clearfix;
}

.footer-col {
  float: left;
  margin-bottom: $spacing-unit / 2;
  padding-left: $spacing-unit / 2;
}

.footer-col-1 {
  width: -webkit-calc(35% - (#{$spacing-unit} / 2));
  width:         calc(35% - (#{$spacing-unit} / 2));
}

.footer-col-2 {
  width: -webkit-calc(20% - (#{$spacing-unit} / 2));
  width:         calc(20% - (#{$spacing-unit} / 2));
}

.footer-col-3 {
  width: -webkit-calc(45% - (#{$spacing-unit} / 2));
  width:         calc(45% - (#{$spacing-unit} / 2));
}

@include media-query($on-laptop) {
  .footer-col-1,
  .footer-col-2 {
    width: -webkit-calc(50% - (#{$spacing-unit} / 2));
    width:         calc(50% - (#{$spacing-unit} / 2));
  }

  .footer-col-3 {
    width: -webkit-calc(100% - (#{$spacing-unit} / 2));
    width:         calc(100% - (#{$spacing-unit} / 2));
  }
}

@include media-query($on-palm) {
  .footer-col {
    float: none;
    width: -webkit-calc(100% - (#{$spacing-unit} / 2));
    width:         calc(100% - (#{$spacing-unit} / 2));
  }
}



/**
 * Page content
 */
.page-content {
  padding: $spacing-unit 0;
  flex: 1;
}

.page-header {
  margin-bottom: $spacing-unit;
  background-color: $brand-navy;
  margin-bottom: 9.5rem;
  padding-top: 17.5rem;
  padding-bottom: 13rem;
  color: $brand-white;
  text-align: center;
}

.page-heading {
  @include relative-font-size(4.6);
}

.page-subheading {
  @include relative-font-size(1.6);
}

.post-list-heading {
  @include relative-font-size(1.75);
}

.standard-hero {
  background-color: $brand-white;
  padding-top: 17.5rem;
  padding-bottom: 13rem;
  color: $colors-gray-1000;
  text-align: left;
}

.type-heading__1 {
  @include relative-font-size(3);
  max-width: 18em;
}

.type-heading__2 {
  @include relative-font-size(2.5);
  max-width: 25em;
}

.type-heading__3 {
  @include relative-font-size(2);
  max-width: 28em;
}

.type-body__3 {
  font-size: 23px;
  line-height: 1.3;
  max-width: 30em;
}

.type-body__4 {
  font-size: 18px;
  line-height: 1.5;
  max-width: 45em;
}

.standard-hero__subheader {
  @include relative-font-size(1.2);
}

.post-list {
  margin-left: 0;
  list-style: none;

  > li {
    margin-bottom: $spacing-unit;
  }
}

.post-meta {
  font-size: $small-font-size;
  color: $brand-white;
}
.post-meta.dark{
  color: $grey-color;
}

.post-avatar {
  height: 130px;
  width: 130px;
  border-radius: 50%;
  border: 4px solid rgba(0, 0, 0, .15);
  position: relative;
  overflow: hidden;
  margin: 0 auto;
  margin-bottom: 10px;

  img {
    height: auto;
    width: 100%;
  }
}

.post-link {
  display: block;
  @include relative-font-size(1.5);
  font-weight: 600;
  color: $colors-gray-1000;

  &:hover {
    color: $colors-indigo-400;
  }
}



/**
 * Posts
 */
.post-header {
  margin-bottom: $spacing-unit;
  background-color: $colors-gray-1000;
  margin-bottom: 9.5rem;
  padding-top: 17.5rem;
  padding-bottom: 13rem;
  color: #fff;
  text-align: center;
}

.post-header-ooey-gooey{
  background-image: -webkit-gradient(linear,left top,left bottom,from(#0700b8),to(#00ff88));
  background-image: linear-gradient(to bottom,#0700b8 0, #00ff88 100%);
}

.post-header-bloody-mimosa{
  background-image: -webkit-gradient(linear,left top,left bottom,from(#d53369),to(#daae51));
  background-image: linear-gradient(to bottom,#d53369 0, #daae51 100%);
}

.post-header-aqua-spray{
  background-image: -webkit-gradient(linear,left top,left bottom,from(#3a47d5),to(#00d2ff));
  background-image: linear-gradient(to bottom, #3a47d5 0, #00d2ff 100%);
}

.post-header-lovely-lilly{
  background-image: -webkit-gradient(linear,left top,left bottom, from(#515ada),to(#efd5ff));
  background-image: linear-gradient(to bottom, #515ada 0, #efd5ff 100%);
}

.post-header-sea-salt{
  background-image: -webkit-gradient(linear, left top, left bottom, from(#4b6cb7),to(#182848));
  background-image: linear-gradient(to bottom, #4b6cb7 0, #182848 100%);
}

.post-title {
  @include relative-font-size(3.2);
  letter-spacing: -1px;
  line-height: 1;

  margin: 0 auto 4rem;

  @include media-query($on-laptop) {
    @include relative-font-size(3.25);
  }
}

.post-content {
  margin-bottom: $spacing-unit;
  font-size: 18px;

  h1, h2, h3, h4, h5 {
    margin-top: 55px;
    margin-bottom: 27.5px;
  }

  h2 {
    @include relative-font-size(2);

    @include media-query($on-laptop) {
      @include relative-font-size(1.75);
    }
  }

  h3 {
    @include relative-font-size(1.625);

    @include media-query($on-laptop) {
      @include relative-font-size(1.375);
    }
  }

  h4 {
    @include relative-font-size(1.25);

    @include media-query($on-laptop) {
      @include relative-font-size(1.125);
    }
  }
}

/**
 * Elements
 */

.categories{
  padding-bottom: 20px;

  .p-category{
    text-transform: uppercase;
    font-weight: 700;
    color: $colors-teal-500;
    font-size: 14px;
  }
  .p-category-wrap:not(:last-of-type)::after{
    content: ",";
    display: inline-block;
    margin-right: 4px;
  }
}

.contained-column {
  max-width: 550px;
  margin: 0 auto;
}

article.post, .post-list, .post-list-heading {
  font-family: -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
}

article.post {
  h1 {
    font-weight: 700;
  }
  h2, h3 {
    font-weight: 500;
  }
}

 @media (max-width: 749px) {
  .btm-half__mobile {
    padding-top: 50px;
  }
}

.highlighter {
  background-image: linear-gradient(92deg, rgba(62, 189, 147, 0.20), rgba(62, 189, 147, 0.6), rgba(62, 189, 147, .35));
  border-radius: 4% 36% 16% 45%;
}

.highlighter__red {
  background-image: linear-gradient(92deg, rgba(214, 69, 69, 0.20), rgba(214, 69, 69, 0.6), rgba(214, 69, 69, .35));
  border-radius: 4% 36% 16% 45%;
}

.highlighter__peach {
  background-image: linear-gradient(92deg, rgba(216, 170, 150, 0.20), rgba(216, 170, 150, 0.6), rgba(216, 170, 150, .35));
  border-radius: 4% 36% 16% 45%;
}
